// src/components/SurveyPage.js
import React, { useEffect } from "react";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { useTranslation } from "react-i18next";
import axios from "axios";

const SurveyPage = () => {
  const { t } = useTranslation();
  const surveyJson = require(`./survey/${t("language")}.json`);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  }, []);

  // Create a survey model from your JSON schema
  const survey = new Model(surveyJson);

  // Handle survey completion
  survey.onComplete.add(async (sender) => {
    console.log("Survey results:", sender.data);

    try {
      // Send the survey results to the backend server
      await axios.post(
        "https://winedao.vercel.app/api/saveSurvey",
        sender.data
      );
      console.log(sender.data);
      console.log("Survey results successfully uploaded to the server.");
    } catch (error) {
      console.error("Error uploading survey results:", error);
    }
  });

  return (
    <div style={{ padding: "20px" }}>
      <Survey model={survey} />
    </div>
  );
};

export default SurveyPage;
