// src/components/Hero.js
import "bootstrap-icons/font/bootstrap-icons.css";
import React from "react";
import GrapeSVG from "./GrapeSVG"; // Import the GrapeSVG component
import { Link as RouterLink, useNavigate } from "react-router-dom";
import "./Hero.css";

import Number01 from "../images/01.svg";
import Number02 from "../images/02.svg";
import Number03 from "../images/03.svg";

import { useTranslation } from "react-i18next";

export default function Hero() {
  const { t } = useTranslation();

  return (
    <section className="hero">
      <div className="container-fluid">
        {/* Top Left Grape */}
        <div className="svg-wrapper-top svg-left-top">
          <GrapeSVG width={40} height={40} fill="#b02000" />
        </div>

        {/* Top Right Grape */}
        <div className="svg-wrapper-top svg-right-top">
          <GrapeSVG width={40} height={40} fill="#b02000" />
        </div>

        {/* Bottom Left Grape */}
        <div className="svg-wrapper-bottom svg-left-bottom">
          <GrapeSVG width={62} height={62} fill="#b02000" />
        </div>

        {/* Bottom Right Grape */}
        <div className="svg-wrapper-bottom svg-right-bottom">
          <GrapeSVG width={62} height={62} fill="#b02000" />
        </div>

        {/* Content */}
        <div className="content">
          <h1>
            {t("invest")}
            <br />
            <span>{t("grow")}</span>
          </h1>
          <p className="subtitle">{t("helpUs")}</p>
          {/* Survey CTA */}
          <div className="cta">
            <RouterLink
              to="/survey" // Replace with your actual survey URL
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("takeTheSurvey")}
            </RouterLink>
          </div>
          <div className="panel">
            <div className="image-container">
              {/* First Image */}
              <img
                src={Number01}
                alt="01"
                className="number-image small-image"
              />

              {/* Middle Image */}
              <img
                src={Number02}
                alt="02"
                className="number-image large-image"
              />

              {/* Third Image */}
              <img
                src={Number03}
                alt="03"
                className="number-image small-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
