import React from "react";
import Hero from "./components/Hero";
import Why from "./components/Why";
import About from "./components/About";
import Roadmap from "./components/Roadmap";
import Team from "./components/Team";
import Questions from "./components/QnA";
import Break from "./components/Break";

function HomePage() {
  return (
    <div>
      <Hero id="hero" />
      <Break />
      <Why id="why" />
      <Break />
      <About id="about" />
      <Break />
      <Roadmap id="roadmap" />
      <Break />
      <Team id="team" />
      <Break />
      <Questions id="faq" />
    </div>
  );
}

export default HomePage;
