// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files
import enTranslation from "./locales/en/translation.json";
import deTranslation from "./locales/de/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      de: {
        translation: deTranslation,
      },
      // Add more languages here
    },
    fallbackLng: "en", // Fallback language in case translation is missing

    keySeparator: false, // We do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    detection: {
      order: ["cookie", "localStorage", "htmlTag", "path", "subdomain"],
    },
  });

export default i18n;
