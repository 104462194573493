import React, { useState, useEffect } from "react";
import { Link as ScrollLink, scroller } from "react-scroll";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import logo from "../images/logo.PNG";
import "./Navbar.css";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.scrollY > 0;
      setIsSticky(isScrolling);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { t } = useTranslation();

  // New function to navigate to the homepage and scroll to the section
  const handleNavigationToSection = (sectionId) => {
    // Navigate to the homepage
    navigate("/");
    // Scroll to the desired section after navigation
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 100);
  };

  return (
    <section className={`Navbar${isSticky ? " sticky" : ""}`}>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          {/* Logo */}
          <RouterLink to="/" onClick={handleCloseMenu}>
            <img src={logo} alt="Logo" className="navbar-brand" />
          </RouterLink>

          {/* Navbar Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded={isMenuOpen ? "true" : "false"}
            onClick={handleMenuToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Offcanvas Menu */}
          <div
            className={`offcanvas offcanvas-end ${isMenuOpen ? "show" : ""}`}
            id="navbarText"
            tabIndex="-1"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title">Menu</h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={handleCloseMenu}
              ></button>
            </div>
            <div className="offcanvas-body">
              {/* Navigation Links */}
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <RouterLink
                    to="/"
                    onClick={() => {
                      handleCloseMenu();
                      handleNavigationToSection("about");
                    }}
                    className="nav-link"
                  >
                    {t("about")}
                  </RouterLink>
                </li>
                <li className="nav-item">
                  <RouterLink
                    to="/"
                    onClick={() => {
                      handleCloseMenu();
                      handleNavigationToSection("roadmap");
                    }}
                    className="nav-link"
                  >
                    {t("roadmap")}
                  </RouterLink>
                </li>
                <li className="nav-item">
                  <RouterLink
                    to="/"
                    onClick={() => {
                      handleCloseMenu();
                      handleNavigationToSection("team");
                    }}
                    className="nav-link"
                  >
                    {t("team")}
                  </RouterLink>
                </li>
                <li className="nav-item">
                  <RouterLink
                    to="/"
                    onClick={() => {
                      handleCloseMenu();
                      handleNavigationToSection("faq");
                    }}
                    className="nav-link"
                  >
                    {t("faq")}
                  </RouterLink>
                </li>
              </ul>

              {/* Social Links 
              <div className="d-flex align-items-center ms-lg-4">
                <a href="https://twitter.com" className="btn">
                  <i className="bi bi-twitter"></i>
                </a>
                <a href="https://discord.com" className="btn">
                  <i className="bi bi-discord"></i>
                </a>
              </div>*/}
            </div>
          </div>

          {/* Desktop "Take the Survey" Button */}
          <div className="d-none d-lg-block survey-button-container cta-navbar">
            <RouterLink
              to="/survey"
              className="survey-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("takeTheSurvey")}
            </RouterLink>
          </div>
        </div>
        <LanguageSwitcher />
      </nav>
    </section>
  );
}

export default Navbar;
