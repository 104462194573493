// src/components/About.js
import React from "react";
import "./About.css";
import communityImage from "../images/communityhelp.svg"; // Update the image path accordingly
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  return (
    <section className="about">
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          {/* About Image */}
          <div className="col-md-6 about-image">
            <img
              src={communityImage}
              className="img-fluid"
              alt="Community Cellar"
            />
          </div>

          {/* About Content */}
          <div className="col-md-6 panel-content">
            <h3 className="about-title">
              {t("abouttitleblack")}
              <br />
              <span>{t("abouttitle")}</span>
            </h3>
            <p className="about-content">{t("aboutdesc")}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
