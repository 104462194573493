// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import HomePage from "./HomePage";

import Break from "./components/Break";
import Footer from "./components/Footer";
import SurveyPage from "./survey";
import ImpressPage from "./impress";

function App() {
  return (
    <Router>
      <Navbar /> {/* Navbar appears on all pages */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/survey" exact element={<SurveyPage />} />
        <Route path="/impress" exact element={<ImpressPage />} />
      </Routes>
      <Break />
      <Footer />
    </Router>
  );
}

export default App;
