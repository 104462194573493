// src/components/Roadmap.js
import React, { useState } from "react";

import "./Roadmap.css";
import { useTranslation } from "react-i18next";

export default function Roadmap() {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState("Initiation");

  const handleClick = (category) => {
    setActiveCategory(category);
  };

  // Updated roadmapData with shorter activity descriptions
  const roadmapData = {
    Initiation: [
      {
        phase: "Phase 1",
        title: t("phase1title"),
        description: t("phase1desc"),
        activities: [
          t("phase1deliverable1"),
          t("phase1deliverable2"),
          t("phase1deliverable3"),
          t("phase1deliverable4"),
        ],
      },
      {
        phase: "Phase 2",
        title: t("phase2title"),
        description: t("phase2desc"),
        activities: [
          t("phase2deliverable1"),
          t("phase2deliverable2"),
          t("phase2deliverable3"),
        ],
      },
    ],
    "Platform Development": [
      {
        phase: "Phase 3",
        title: t("phase3title"),
        description: t("phase3desc"),
        activities: [
          t("phase3deliverable1"),
          t("phase3deliverable2"),
          t("phase3deliverable3"),
        ],
      },
      {
        phase: "Phase 4",
        title: t("phase4title"),
        description: t("phase4desc"),
        activities: [
          t("phase4deliverable1"),
          t("phase4deliverable2"),
          t("phase4deliverable3"),
        ],
      },
    ],

    "Long Term": [
      {
        phase: "Phase 5",
        title: t("phase5title"),
        description: t("phase5desc"),
        activities: [
          t("phase5deliverable1"),
          t("phase5deliverable2"),
          t("phase5deliverable3"),
        ],
      },
      {
        phase: "Phase 6",
        title: t("phase6title"),
        description: t("phase6desc"),
        activities: [
          t("phase6deliverable1"),
          t("phase6deliverable2"),
          t("phase6deliverable3"),
        ],
      },
    ],
  };

  return (
    <section className="roadmap">
      <div className="container-fluid">
        <h1 className="roadmap-title">
          {t("roadmaptitleblack")} <span>{t("roadmaptitle")}</span>
        </h1>
        <div className="row">
          {/* Left Column: List of Categories */}
          <div className="col-md-4">
            <div className="text-column">
              <ul>
                {Object.keys(roadmapData).map((category, index) => (
                  <li
                    key={index}
                    className={activeCategory === category ? "active" : ""}
                    onClick={() => handleClick(category)}
                  >
                    <span>{(index + 1).toString().padStart(2, "0")}</span>{" "}
                    {category}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Right Column: Phase Details */}
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {roadmapData[activeCategory].map((phaseData, index) => (
                    <div className="col-md-6" key={index}>
                      <div className="phase-card mb-4">
                        <p className="card-phase">{phaseData.phase}</p>
                        <h3 className="card-title">{phaseData.title}</h3>
                        <p className="card-text">{phaseData.description}</p>
                        <hr />
                        <ul>
                          {phaseData.activities.map((activity, idx) => (
                            <li key={idx}>
                              <i className="bi bi-check-circle"></i> {activity}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
